
const font = {
    notoSansKr: {
        variable: 'var(--font-noto-sans-cjk-kr)',
    },
};

const style = {
    font: font,
    textStyle: {
        headline1Bold: {
            textStyle: {
                fontSize: '24px',
                fontWeight: 700, // Bold
            },
        },
        headline1Medium: {
            textStyle: {
                fontSize: '24px',
                fontWeight: 500, // Medium
            },
        },
        headline1Regular: {
            textStyle: {
                fontSize: '24px',
                fontWeight: 400, // Regular
            },
        },
        headline2_20Bold: {
            textStyle: {
                fontSize: '20px',
                fontWeight: 700, // Bold
            },
        },
        headline2_20Medium: {
            textStyle: {
                fontSize: '20px',
                fontWeight: 500, // Medium
            },
        },
        headline2_20Regular: {
            textStyle: {
                fontSize: '20px',
                fontWeight: 400, // Regular
            },
        },
        headline2_18Bold: {
            textStyle: {
                fontSize: '18px',
                fontWeight: 700, // Bold
            },
        },
        headline2_18Medium: {
            textStyle: {
                fontSize: '18px',
                fontWeight: 500, // Medium
            },
        },
        headline2_18Regular: {
            textStyle: {
                fontSize: '18px',
                fontWeight: 400, // Regular
            },
        },
        headline2_16Bold: {
            textStyle: {
                fontSize: '16px',
                fontWeight: 700, // Bold
            },
        },
        headline2_16Medium: {
            textStyle: {
                fontSize: '16px',
                fontWeight: 500, // Medium
            },
        },
        headline2_16Regular: {
            textStyle: {
                fontSize: '16px',
                fontWeight: 400, // Regular
            },
        },
        headline2_15Bold: {
            textStyle: {
                fontSize: '15px',
                fontWeight: 700, // Bold
            },
        },
        headline2_15Medium: {
            textStyle: {
                fontSize: '15px',
                fontWeight: 500, // Medium
            },
        },
        headline2_15Regular: {
            textStyle: {
                fontSize: '15px',
                fontWeight: 400, // Regular
            },
        },
        headline2_14Bold: {
            textStyle: {
                fontSize: '14px',
                fontWeight: 700, // Bold
            },
        },
        headline2_14Medium: {
            textStyle: {
                fontSize: '14px',
                fontWeight: 500, // Medium
            },
        },
        headline2_14Regular: {
            textStyle: {
                fontSize: '14px',
                fontWeight: 400, // Regular
            },
        },
        headline2_12Bold: {
            textStyle: {
                fontSize: '12px',
                fontWeight: 700, // Bold
            },
        },
        headline2_12Medium: {
            textStyle: {
                fontSize: '12px',
                fontWeight: 500, // Medium
            },
        },
        headline2_12Regular: {
            textStyle: {
                fontSize: '12px',
                fontWeight: 400, // Regular
            },
        },
        headline2_10Bold: {
            textStyle: {
                fontSize: '10px',
                fontWeight: 700, // Bold
            },
        },
        headline2_10Medium: {
            textStyle: {
                fontSize: '10px',
                fontWeight: 500, // Medium
            },
        },
        headline2_10Regular: {
            textStyle: {
                fontSize: '10px',
                fontWeight: 400, // Regular
            },
        },
    },
    color: {
        palette: {
            primary: {
              main: '#2073F6',
              contrastText: '#FFFFFF',
            },
            secondary: {
              main: '#FDD000',
            },
            secondary2: {
              main: '#FF2164',
            },
            secondary3: {
              main: '#FF9241',
            },
            error: {
              main: '#FF4A4D',
            },
            background: {
              default: '#FFFFFF',
              paper: '#F9F9F9',
            //   box: 'blue'
            },
            text: {
              primary: '#222222',
              secondary: '#666666',
            },
            grayscale: {
              gray01: '#FFFFFF',
              gray02: '#F9F9F9',
              gray03: '#EEEEEE',
              gray04: '#BBBBBB',
              gray05: '#999999',
              gray06: '#666666',
              gray07: '#000000',
            },
          },
    },
};

export default style;

    // color: {
    //     system: {
    //         danger: '#FF6363',
    //         successful: '#10C0A1',
    //         info: '#145BE4',
    //         warning: '#E98C00',
    //     },
    //     mobile: {
    //         sys: {
    //             dark: {
    //                 onSurfaceVariant: '#CCC4CE',
    //                 blackCommonColor: '#000000',
    //             },
    //             light: {
    //                 primary: '#9F32FF',
    //                 secondary: '#00B27D',
    //                 extra: '#F4EBFB',
    //                 error: '#EE1E1E',
    //                 onSurfaceVariant: '#7C757E',
    //                 onSurface: '#2C003D',
    //                 outlineAlt: '#E6DBE9',
    //                 outlineVariant: '#B1A8B3',
    //                 surface: '#FFFFFF',
    //                 surfaceVariant: '#F3F4FB',
    //                 inverseSurface: '#2C003D',
    //                 inverseOnSurface: '#FFFFFF',
    //                 linkEnabled: '#2073F6',
    //                 tag: '#2073F6',
    //                 expand: '#666666',
    //                 button: '#FDD000',
    //                 info: '#FF2164',
    //             },
    //         },
    //         colorIcon: {
    //             heart: {
    //                 active: '#FF2164',
    //                 notActive: '#000000',
    //             },
    //         },
    //         button: {
    //             fixedButtonDisable: {
    //                 main: '#BBBBBB',
    //                 contrastText: '#FFFFFF',
    //             },
    //             fixedButtonActive: {
    //                 main: '#2073F6',
    //                 contrastText: '#FFFFFF',
    //             },
    //             basicButtonActive: {
    //                 main: '#FDD000',
    //                 contrastText: '#000000',
    //             },
    //             basicButtonDisable: {
    //                 main: '#EEEEEE',
    //                 contrastText: '#666666',
    //             },
    //         },
    //         stateLayers: {
    //             light: {
    //                 surface: {
    //                     opacity16: '#EEEEEE',
    //                 },
    //                 tertiary: {
    //                     opacity8: 'rgba(128, 81, 87, 0.08)',
    //                 },
    //             },
    //         },
    //     },

