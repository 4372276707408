'use client';
import React, { useEffect, ReactNode } from 'react';
import { useAuthenticationPopUp } from '../contexts/authentication-popup-context';
import axios from './axios';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCookie } from 'cookies-next';
import { clearAuthIgnoreCookie, clearUserCookies, deleteExpiredToken, getAccessToken, setAuthIgnoreCookie } from '../helpers/cookiesMethod';
import { useRouter } from 'next/navigation';

interface AxiosErrorHandlerProps {
    children: ReactNode;
}

const COOKIE_NAME = 'auth_ignore';
const AxiosErrorHandler: React.FC<AxiosErrorHandlerProps> = ({ children }) => {
    const { confirmPopUp } = useAuthenticationPopUp();
    const router = useRouter();

    const handleCancel = () => {
        clearUserCookies();
        deleteExpiredToken();
        setAuthIgnoreCookie(COOKIE_NAME, 1);
        confirmPopUp.close();
    };

    const handleAccept = () => {
        clearAuthIgnoreCookie(COOKIE_NAME);
        clearUserCookies();
        deleteExpiredToken();
        router.push('/login');
        confirmPopUp.close();
    };

    const handleCancelWithLogout = () => {
        setAuthIgnoreCookie(COOKIE_NAME, 1);
        clearUserCookies();
        deleteExpiredToken();
        confirmPopUp.close();
        // window.location.reload()
        router.replace('/login');
    };

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            async (error: AxiosError | any) => {
                const originalRequest = error?.config as AxiosRequestConfig;
                if (error?.status === 500 && error?.code === 'ERR_NETWORK' && error.code !== 'ERR_CANCELED') {
                    //handle only Error server
                    //detect avoid canceled  => but validate error undefined
                    confirmPopUp.open({ acceptActions: handleCancelWithLogout, showCloseButton: false, title: error?.data?.msg || error?.msg });
                    return;
                }
                if (error?.status && error.status === 410) {
                    confirmPopUp.open({ acceptActions: handleAccept, showCloseButton: false, title: error?.data?.msg });
                }
                if (error?.status && error.status === 403 && error?.data?.is_deleted) {
                    confirmPopUp.open({ acceptActions: handleAccept, showCloseButton: false, title: error?.data?.msg });
                }

                if (error.status && error.status === 401) {
                    if (originalRequest.method === 'get') {
                        if (!getCookie(COOKIE_NAME)) {
                            //this mean auth is not close before and have access token but expired
                            confirmPopUp.open({ acceptActions: handleAccept, cancelActions: handleCancel });
                        }
                    } else {
                        if (originalRequest.url?.includes('refresh-token')) {
                            //refresh token fail
                            confirmPopUp.open({ acceptActions: handleAccept, cancelActions: handleCancel, showCloseButton: false });
                            //return to login
                        } else if (originalRequest.url?.includes('logout')) {
                            confirmPopUp.open({ acceptActions: handleAccept, cancelActions: handleCancelWithLogout });
                        } else {
                            confirmPopUp.open({ acceptActions: handleAccept, cancelActions: handleCancel });
                        }
                    }
                }
                return Promise.reject(error);
            }
        );
        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [confirmPopUp]);

    return children;
};

export default AxiosErrorHandler;
