'use client';
import { LOCAL_STORAGE_TYPES } from '@/app/constants/typedef';
import { firebaseCloudMessaging } from '@/app/lib/firebase/firebase_service';
import { useEffect } from 'react';

export const NotificationHandler = () => {
    // const setupNotificationListeners = async () => {
    //     (async () => {
    //         await firebaseCloudMessaging.init();
    //         if (firebaseCloudMessaging.DEVICE_TOKEN_STRING) {
    //             localStorage.setItem(LOCAL_STORAGE_TYPES.DEVICE_TOKEN, firebaseCloudMessaging.DEVICE_TOKEN_STRING);
    //         } else {
    //         }
    //     })();
    // };

    // useEffect(() => {
    //     setupNotificationListeners();
    // }, []);

    return null;
};

export default NotificationHandler;
