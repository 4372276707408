'use client';
import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import style from './style';
import { MAX_SCREEN_WIDTH } from '../constants/layoutConfig';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        h1Medium?: React.CSSProperties;
        h1Regular?: React.CSSProperties;
        h2_20Bold?: React.CSSProperties;
        h2_20Medium?: React.CSSProperties;
        h2_20Regular?: React.CSSProperties;
        h2_18Bold?: React.CSSProperties;
        h2_18Medium?: React.CSSProperties;
        h2_18Regular?: React.CSSProperties;
        h2_16Bold?: React.CSSProperties;
        h2_16Medium?: React.CSSProperties;
        h2_16Regular?: React.CSSProperties;
        h2_15Bold?: React.CSSProperties;
        h2_15Medium?: React.CSSProperties;
        h2_15Regular?: React.CSSProperties;
        h2_14Bold?: React.CSSProperties;
        h2_14Medium?: React.CSSProperties;
        h2_14Regular?: React.CSSProperties;
        h2_12Bold?: React.CSSProperties;
        h2_12Medium?: React.CSSProperties;
        h2_12Regular?: React.CSSProperties;
        h2_10Bold?: React.CSSProperties;
        h2_10Medium?: React.CSSProperties;
        h2_10Regular?: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        h1Medium?: React.CSSProperties;
        h1Regular?: React.CSSProperties;
        h2_20Bold?: React.CSSProperties;
        h2_20Medium?: React.CSSProperties;
        h2_20Regular?: React.CSSProperties;
        h2_18Bold?: React.CSSProperties;
        h2_18Medium?: React.CSSProperties;
        h2_18Regular?: React.CSSProperties;
        h2_16Bold?: React.CSSProperties;
        h2_16Medium?: React.CSSProperties;
        h2_16Regular?: React.CSSProperties;
        h2_15Bold?: React.CSSProperties;
        h2_15Medium?: React.CSSProperties;
        h2_15Regular?: React.CSSProperties;
        h2_14Bold?: React.CSSProperties;
        h2_14Medium?: React.CSSProperties;
        h2_14Regular?: React.CSSProperties;
        h2_12Bold?: React.CSSProperties;
        h2_12Medium?: React.CSSProperties;
        h2_12Regular?: React.CSSProperties;
        h2_10Bold?: React.CSSProperties;
        h2_10Medium?: React.CSSProperties;
        h2_10Regular?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h1Medium: true;
        h1Regular: true;
        h2_20Bold: true;
        h2_20Medium: true;
        h2_20Regular: true;
        h2_18Bold: true;
        h2_18Medium: true;
        h2_18Regular: true;
        h2_16Bold: true;
        h2_16Medium: true;
        h2_16Regular: true;
        h2_15Bold: true;
        h2_15Medium: true;
        h2_15Regular: true;
        h2_14Bold: true;
        h2_14Medium: true;
        h2_14Regular: true;
        h2_12Bold: true;
        h2_12Medium: true;
        h2_12Regular: true;
        h2_10Bold: true;
        h2_10Medium: true;
        h2_10Regular: true;
    }
}

declare module '@mui/material' {
    interface Palette {
        border: string;
        extra: Palette['primary'];
        cancel: Palette['primary'];
        link: Palette['primary'];
        fixedButtonDisable: Palette['primary'];
        secondary2: Palette['primary'];
        secondary3?: Palette['primary'];
        fixedButtonActive: Palette['primary'];
        basicButtonActive: Palette['primary'];
        basicButtonDisable: Palette['primary'];
        grayscale: {
            gray01: string;
            gray02: string;
            gray03: string;
            gray04: string;
            gray05: string;
            gray06: string;
            gray07: string;
        };
    }

    interface PaletteOptions {
        border?: string;
        extra?: PaletteOptions['primary'];
        cancel?: PaletteOptions['primary'];
        link?: PaletteOptions['primary'];
        sectionColor?: string;
        secondary2: PaletteOptions['primary'];
        secondary3?: PaletteOptions['primary'];
        fixedButtonDisable?: PaletteOptions['primary'];
        fixedButtonActive?: PaletteOptions['primary'];
        basicButtonActive?: PaletteOptions['primary'];
        basicButtonDisable?: PaletteOptions['primary'];
        grayscale?: {
            gray01?: string;
            gray02?: string;
            gray03?: string;
            gray04?: string;
            gray05?: string;
            gray06?: string;
            gray07?: string;
        };
    }

    interface PaletteColor {
        gray01?: string;
        gray02?: string;
        gray03?: string;
        gray04?: string;
        gray05?: string;
        gray06?: string;
        gray07?: string;
    }

    interface TypeBackground {
        surfaceVariant: string;
        inverseSurface: string;
        input: string;
        divider: string;
        button: string;
    }

    interface TypeBackgroundOptions {
        surfaceVariant?: string;
        inverseSurface?: string;
        input?: string;
        divider?: string;
    }

    interface ButtonPropsColorOverrides {
        extra: true;
        cancel: true;
        link: true;
        fixedButtonDisable: true;
        fixedButtonActive: true;
        basicButtonActive: true;
        basicButtonDisable: true;
    }
}

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: [style.font.notoSansKr.variable, 'sans-serif'].join(','),
        h1: {
            ...style.textStyle.headline1Bold.textStyle,
        },
        allVariants: {
            color: '#000000',
        },
        h1Medium: style.textStyle.headline1Medium.textStyle,
        h1Regular: style.textStyle.headline1Regular.textStyle,
        h2_20Bold: style.textStyle.headline2_20Bold.textStyle,
        h2_20Medium: style.textStyle.headline2_20Medium.textStyle,
        h2_20Regular: style.textStyle.headline2_20Regular.textStyle,
        h2_18Bold: style.textStyle.headline2_18Bold.textStyle,
        h2_18Medium: style.textStyle.headline2_18Medium.textStyle,
        h2_18Regular: style.textStyle.headline2_18Regular.textStyle,
        h2_16Bold: style.textStyle.headline2_16Bold.textStyle,
        h2_16Medium: style.textStyle.headline2_16Medium.textStyle,
        h2_16Regular: style.textStyle.headline2_16Regular.textStyle,
        h2_15Bold: style.textStyle.headline2_15Bold.textStyle,
        h2_15Medium: style.textStyle.headline2_15Medium.textStyle,
        h2_15Regular: style.textStyle.headline2_15Regular.textStyle,
        h2_14Bold: style.textStyle.headline2_14Bold.textStyle,
        h2_14Medium: style.textStyle.headline2_14Medium.textStyle,
        h2_14Regular: style.textStyle.headline2_14Regular.textStyle,
        h2_12Bold: style.textStyle.headline2_12Bold.textStyle,
        h2_12Medium: style.textStyle.headline2_12Medium.textStyle,
        h2_12Regular: style.textStyle.headline2_12Regular.textStyle,
        h2_10Bold: style.textStyle.headline2_10Bold.textStyle,
        h2_10Medium: style.textStyle.headline2_10Medium.textStyle,
        h2_10Regular: style.textStyle.headline2_10Regular.textStyle,
    },

    palette: {
        primary: {
            main: '#2073F6',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FDD000',
        },
        secondary2: {
            main: '#FF2164',
        },
        secondary3: {
            main: '#FF9241',
        },
        error: {
            main: '#FF4A4D',
        },
        background: {
            default: '#FFFFFF',
            paper: '#F9F9F9',
        },
        text: {
            primary: '#222222',
            secondary: '#666666',
        },
        grayscale: {
            gray01: '#FFFFFF',
            gray02: '#F9F9F9',
            gray03: '#EEEEEE',
            gray04: '#BBBBBB',
            gray05: '#999999',
            gray06: '#666666',
            gray07: '#000000',
        },
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 751,
            laptop: 1024,
        },
    },
    spacing: 4,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    '&::-webkit-scrollbar': {
                        width: '.5rem',
                        height: '.5rem',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#FFFFF',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#C8CFD5',
                        borderRadius: '8px',
                    },
                },
            },
        },

        MuiTypography: {
            styleOverrides: {},
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    maxWidth: MAX_SCREEN_WIDTH,
                    '@media (min-width: 768px)': {
                        '&::-webkit-scrollbar': {
                            width: '.5rem',
                            height: '.5rem',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#FFFFF',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#C8CFD5',
                            borderRadius: '8px',
                        },
                    },
                },
                container: {
                    '@media (min-width: 768px)': {
                        '&::-webkit-scrollbar': {
                            width: '.5rem',
                            height: '.5rem',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#FFFFF',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#C8CFD5',
                            borderRadius: '8px',
                        },
                    },
                },
            },
        },

        MuiButton: {
            variants: [
                {
                    props: { color: 'fixedButtonDisable' },
                    style: {
                        backgroundColor: '#BBBBBB',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#AAAAAA',
                        },
                    },
                },
                {
                    props: { color: 'fixedButtonActive' },
                    style: {
                        backgroundColor: '#2073F6',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#1A5BCC',
                        },
                    },
                },
                {
                    props: { color: 'basicButtonActive' },
                    style: {
                        backgroundColor: '#FDD000',
                        color: '#000000',
                        '&:hover': {
                            backgroundColor: '#E6C200',
                        },
                    },
                },
                {
                    props: { color: 'basicButtonDisable' },
                    style: {
                        backgroundColor: '#EEEEEE',
                        color: '#666666',
                        '&:hover': {
                            backgroundColor: '#DDDDDD',
                        },
                    },
                },
            ],
        },
        MuiButtonBase: {
            defaultProps: {
                sx: {
                    color: '#000000',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&::placeholder': {
                        color: '#666666',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        // backgroundColor: '#000000',
                        height: '3px',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#000000',
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.severity === 'info' &&
                        {
                            // backgroundColor: '#60a5fa',
                        }),
                }),
            },
        },
    },
});

export default theme;
