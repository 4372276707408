'use client';
import BaseModal from '@/app/base-ui/BaseModalUI';
import { useAuthenticationPopUp } from '@/app/contexts/authentication-popup-context';
import { clearAuthIgnoreCookie, setAuthIgnoreCookie } from '@/app/helpers/cookiesMethod';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

const COOKIE_NAME = 'auth_ignore';
const PopUpConfirmAuthentication = () => {
    const router = useRouter();
    const params = usePathname();
    const { isDialogOpen, confirmPopUp } = useAuthenticationPopUp();

    useEffect(() => {
        if (['/login'].includes(params) && isDialogOpen) {
            clearAuthIgnoreCookie(COOKIE_NAME);
            confirmPopUp.close();
        }
    }, [params]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        confirmPopUp.close();
    };

    if (isDialogOpen) {
        return (
            <BaseModal
                open={isDialogOpen}
                onClose={handleClose}
                title={confirmPopUp.title}
                description={''}
                showCancel={confirmPopUp.showCloseButton ?? true}
                showAccept={confirmPopUp.showAcceptButton ?? true}
                onCancel={confirmPopUp?.cancelActions || (() => null)}
                onAccept={confirmPopUp?.acceptActions || (() => null)}
                cancelText="아니요"
                acceptText="예"
            ></BaseModal>
        );
    }

    return null;
};

export default PopUpConfirmAuthentication;
