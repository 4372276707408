"use client"
import React, { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';

interface CenteredBoxProps extends BoxProps {}

const CenteredBox = forwardRef<HTMLDivElement, CenteredBoxProps>((props, ref) => {
    return (
        <Box
            {...props}
            ref={ref}
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                ...props.sx, // Allows for additional styles to be passed in
            }}
        >
            {props.children}
        </Box>
    );
});

export default CenteredBox;
